.header
  z-index: 9
  position: sticky
  top: 0
  left: 0
  width: 100%
  border-bottom: .1rem solid #E7E7E7

  +mediaMaxWidth(991.98px)
    height: 14.8rem

  @include setHeaderStyle(true)

  body.header-dark &
    @include setHeaderStyle(false)

  &__container
    width: 100%
    background-color: $lightHeaderBgMode
    +mediaMaxWidth(991.98px)
      position: relative
      top: 4.2rem

    body.header-dark &
      background-color: $darkHeaderBgMode

    &--inner
      display: flex
      align-content: center
      align-items: center
      justify-content: space-between
      width: 100%
      max-width: 124rem
      margin: 0 auto
      padding: 1.6rem
      gap: 2rem

      +mediaMinWidth(992px)
        padding: 2.4rem
        gap: 0

      &-burger
        display: flex
        align-items: flex-start
        flex-grow: 1
        padding: 0

        +mediaMinWidth(992px)
          display: none

        button
          cursor: pointer
          padding: 1.2rem
          background-color: transparent
          border: none
          outline: none
          appearance: none
          width: 5.4rem
          height: 5.4rem
          left: 0
          top: 0
          border: 0
          transform: translate3d(0, 0, 0)
          transition: transform 500ms cubic-bezier(0.77, 0, 0.175, 1) 400ms

          &:before,
          &:after
            content: ''
            display: block
            position: absolute
            background-color: $color_black
            transform-origin: 50% 50%
            transition: transform 300ms cubic-bezier(0.4, 0.8, 0.74, 1) 0ms

            body.header-dark &
              background-color: $color_white

          &:before
            width: 1.8rem
            height: .2rem
            top: 2.6rem
            left: 1.8rem
            transform: scale3d(0, 1, 1) rotate3d(0, 0, 1, 45deg)

          &:after
            width: .2rem
            height: 1.8rem
            right: 2.6rem
            top: 1.8rem
            transform: scale3d(1, 0, 1) rotate3d(0, 0, 1, 45deg)

          svg
            position: relative
            top: .15rem
            transform: scale3d(1, 1, 1)
            transform-origin: 50% 50%
            transition: transform 300ms cubic-bezier(0.4, 0.8, 0.74, 1) 200ms

            body.header-dark &
              path
                fill: $color_white

          &.open
            transform: translate3d(0, 0, 0)
            transition: transform 500ms cubic-bezier(0.77, 0, 0.175, 1) 0ms

            &:before
              transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 45deg)
              transition: transform 300ms cubic-bezier(0.4, 0.8, 0.74, 1) 200ms

            &:after
              transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 45deg)
              transition: transform 300ms cubic-bezier(0.4, 0.8, 0.74, 1) 200ms

            svg
              transform: scale3d(1, 0, 1)
              transition: transform 300ms cubic-bezier(0.4, 0.8, 0.74, 1) 0ms

      &-logo
        svg
          +mediaMaxWidth(475px)
            width: 100%
            max-width: 20rem

        +mediaMaxWidth(991.98px)
          margin: 0 auto
          flex-grow: 1
          padding: 0
          text-align: center

        .logo-header-light
          display: block

        .logo-header-dark
          display: none

        body.header-dark &
          .logo-header-light
            display: none

          .logo-header-dark
            display: block

      &-navigation
        margin: 0 2.4rem

        +mediaMaxWidth(991.98px)
          box-sizing: border-box
          z-index: 10
          position: fixed
          top: 14.7rem
          left: 0
          display: flex
          flex-direction: column
          width: 100%
          height: calc(100% - 14.7rem)
          margin: 0
          padding: 0
          transform: translate3d(-100vw,0,0)
          transition: transform 300ms ease-out
          background: $lightHeaderBgMode
          pointer-events: none
          overflow: hidden
          border-top: .1rem solid #E7E7E7

          body.header-dark &
            background-color: $darkHeaderBgMode

          &.visible
            transform: translate3d(0,0,0)
            pointer-events: auto
            overflow-y: auto

        nav
          display: flex
          flex-direction: column

          +mediaMinWidth(992px)
            flex-direction: row
            align-content: center
            align-items: center
            justify-content: center

          +mediaMaxWidth(991.98px)
            height: 100%

          .item
            +mediaMaxWidth(991.98px)
              width: 100%

            &:not(.item-demo)
              > a
                cursor: pointer
                display: flex
                color: $color_black
                font-family: $default_font
                font-size: 1.6rem
                font-weight: 400
                line-height: 2.4rem

                +mediaMinWidth(992px)
                  align-content: center
                  align-items: center
                  justify-content: center

                +mediaMaxWidth(991.98px)
                  width: 100%
                  padding: 1.6rem
                  border-bottom: .1rem solid #E7E7E7
                  text-align: left

                svg
                  margin-left: .8rem
                  min-width: .8rem

                  +mediaMaxWidth(991.98px)
                    transform: rotate(-90deg)
                    margin: auto 0 auto auto

                body.header-dark &
                  color: $color_white

                  svg
                    path
                      stroke: $color_white

                &.visible
                  svg
                    +mediaMinWidth(992px)
                      transform: rotate(180deg)

            .sub-nav
              z-index: 1
              position: absolute
              top: 0
              left: 0
              right: 0
              width: 100%
              height: 100%
              padding: 1.6rem
              background-color: #E7E7E7
              transform: translate3d(-100vw, 0, 0)
              transition: 300ms all ease-out

              +mediaMinWidth(992px)
                z-index: -1
                top: 12.1rem
                height: auto
                padding: 4.8rem 0 9rem
                transform: translate3d(0, -100%, 0)

              &.visible
                transform: translate3d(0, 0, 0)
                pointer-events: auto
                overflow-y: auto

              &:after
                content: ''
                position: absolute
                top: 0
                left: 0
                width: 100%
                height: 100%
                background-image: url('/img/header/sub-menu-item-bg.svg')
                background-size: cover
                pointer-events: none

              &--container
                width: 100%
                max-width: 124rem
                margin: 0 auto

                +mediaMinWidth(992px)
                  padding: 0 2.4rem

                &-inner
                  width: 100%
                  display: flex
                  flex-direction: row
                  flex-wrap: wrap
                  justify-content: flex-start

                  .back-menu
                    cursor: pointer
                    width: 100%
                    padding: 0
                    background-color: transparent
                    border: none
                    outline: none
                    text-align: left
                    pointer-events: all

                    +mediaMinWidth(992px)
                      pointer-events: none

                    svg
                      position: relative
                      top: -.3rem
                      transform: rotate(90deg)

                      +mediaMinWidth(992px)
                        display: none

                    span
                      font-family: $default_font
                      font-size: 2rem
                      font-weight: 600
                      line-height: 2.6rem
                      text-align: left

                      +mediaMaxWidth(991.98px)
                        margin-top: 1.6rem
                        width: 100%

                  .sub-item
                    display: flex
                    width: 100%
                    margin-top: 2.5rem
                    background-color: $color_white
                    border-radius: .8rem
                    padding: 1.6rem 1.4rem
                    transition: 300ms all ease-out

                    +mediaMinWidth(992px)
                      width: calc(100% / 3 - 1.1rem)

                    &-title,
                    &-subtitle
                      display: block
                      color: $color_black
                      font-family: $default_font
                      font-size: 1.6rem
                      font-weight: 500
                      line-height: 24px
                      transition: 300ms all ease-out

                    &-subtitle
                      margin-top: .8rem
                      font-weight: 300

                    &:hover
                      background-color: #11446B

                      .sub-item-title,
                      .sub-item-subtitle
                        color: $color_white

                    &.sub-item-1
                      .sub-item-title
                        color: #2196F3

                    &.item-clear
                      opacity: 0
                      visibility: hidden
                      pointer-events: none

                      +mediaMaxWidth(991.98px)
                        display: none

                    &:nth-of-type(3n-1)
                      +mediaMinWidth(992px)
                        margin: 2.5rem 1.6rem 0

            &.item-demo
              margin-top: auto
              padding: 1.6rem
              text-align: center

            & + .item
              +mediaMinWidth(992px)
                margin-left: 1.6rem

              +mediaMinWidth(1081px)
                margin-left: 3.6rem

      &-tools
        +mediaMaxWidth(991.98px)
          flex-grow: 1
          padding: 0

        .tools
          display: flex
          align-items: center
          justify-content: flex-end

          &__cta
            +mediaMaxWidth(991.98px)
              position: fixed
              top: 0
              left: 0
              display: flex
              justify-content: flex-end
              width: 100%
              background-color: #072652
              border: none
              outline: none
              padding: 1.1rem
              border-radius: 0
              color: $color_white
              font-family: $default_font
              font-size: 1.4rem
              font-weight: 500
              line-height: 2rem

              svg
                margin-left: 1.5rem

              &:hover
                background-color: #072652

              body.header-dark &
                background-color: #DCFF4F
                color: $color_black

                svg
                  path
                    stroke: $color_black

                &:hover
                  background-color: #DCFF4F

  .language
    position: relative

    +mediaMinWidth(992px)
      margin-right: 8.6rem

    .language-opener
      display: flex
      align-items: center
      cursor: pointer
      background: transparent
      border: none
      outline: none
      padding: 1rem 0
      font-family: $default_font
      font-size: 1.6rem
      font-weight: 400
      line-height: 2.4rem
      color: $color_black
      transition: all 300ms ease-in-out
      text-transform: uppercase

      +mediaMaxWidth(1030px)
        text-align: center

      +mediaMaxWidth(991.98px)
        //padding: 1rem 1.5rem

      svg
        position: relative
        top: -.1rem

        path
          transition: all 300ms ease-in-out

      span
        margin-left: 1.2rem

      body.header-dark &
        color: $color_white

        svg
          path
            stroke: $color_white

    .language-list
      position: absolute
      top: 32px
      left: 0
      right: 0
      display: none

      a
        display: flex
        align-items: center
        cursor: pointer
        background: transparent
        border: none
        outline: none
        padding: 1rem 0
        font-family: $default_font
        font-size: 1.6rem
        font-weight: 400
        line-height: 2.4rem
        color: $color_black
        transition: all 300ms ease-in-out
        text-transform: uppercase

        body.header-dark &
          color: $color_white

        +mediaMaxWidth(991.98px)
          //padding: 1rem 1.5rem

        +mediaMaxWidth(1030px)
          text-align: center

        svg
          path
            transition: all 300ms ease-in-out

        span
          margin-left: 1.2rem

    &.visible
      .language-opener
        svg
          transform: rotate(180deg)

      .language-list
        display: block
