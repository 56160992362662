#back-to-top
  position: fixed
  right: 24px
  bottom: 24px
  z-index: 888
  width: 38px
  height: 38px
  border-radius: 50%
  color: $color_white
  display: inline-flex
  cursor: pointer
  align-items: center
  justify-content: center
  border: 1px solid $color_black
  background-color: $color_blue
  opacity: 0
  visibility: hidden
  transition: .8s

  &.visible
    opacity: 1
    visibility: visible

  svg
    transform: rotate(180deg)

    path
      stroke: $color_white

.footer
  width: 100%
  background-color: $color_black
  margin: 0 auto
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center

  &__top
    padding: 4rem 1.6rem
    width: 100%
    max-width: 124rem
    color: $color_white
    +displayGrid(repeat(2, 1fr), 100px, 50px)

    +mediaMinWidth(768px)
      padding: 14.4rem 2.4rem 11.4rem

    +mediaMaxWidth(1320px)
      max-width: 100%
      row-gap: 50px

    +mediaMaxWidth(1060px)
      +displayGrid(1fr, 0, 80px)


    &--left
      width: 100%

      .title
        font-family: $default_font
        font-size: 4.8rem
        font-weight: 600
        line-height: 5.2rem
        margin: 0

        +mediaMaxWidth(480px)
          font-size: 3.4rem


      .list
        margin: 28px 0 0 0
        list-style-type: none
        +displayGrid(1fr, 0, 15px)

        li
          color: #C0C0C0
          font-family: $default_font
          font-size: 1.6rem
          font-weight: 500
          line-height: 2.4rem
          padding-left: 3.5rem
          position: relative

          svg
            position: absolute
            left: 0
            top: 0


      .brands
        margin: 52px 0 0 0

        &-title
          font-family: $default_font
          font-size: 2.2rem
          font-weight: 600
          line-height: 2.8rem
          letter-spacing: 0.01em

        &-images
          margin: 30px auto 0
          width: fit-content
          +displayGrid(repeat(4, 1fr), 34px, 34px)
          justify-content: center
          align-items: center

          +mediaMaxWidth(480px)
            +displayGrid(repeat(2, 1fr), 34px, 34px)

          &__item
            width: 100%
            +displayFlex(row, center, center)

            .image
              width: 100%
              height: auto
              max-height: 47px
              object-fit: contain


    &--right
      width: 100%
      height: 100%

      .calendar
        width: 100%
        background-color: #EEEEEE
        border: 2px solid $color_black
        border-radius: 24px


        &-top
          width: 100%
          padding: 29px
          +displayGrid(1fr 24px 24px 24px, 22px, 22px)


          &-icon-bar
            max-width: 394px
            width: 100%
            background-color: $color_white
            border: 1px solid #0000001A
            border-radius: 58px

          &-icon-point
            width: 24px
            max-width: 100%
            height: 24px
            border-radius: 50%

            &.yellow
              background-color: #DCFF4F

            &.blue
              background-color: #2196F3

            &.dark-blue
              background-color: #072754


        &-bottom
          width: 100%
          border-top: 2px solid $color_black
          padding: 20px
          +displayFlex(row, center, center)
          color: $color_black
          font-style: italic

          img
            width: 100%
            height: auto
            object-fit: cover

  &__newsletter
    width: 100%
    padding: 4rem 1.6rem
    background-color: #151515

    +mediaMinWidth(768px)
      padding: 7.6rem 2.4rem

    h2
      width: 100%
      max-width: max-content
      margin: auto
      font-family: $default_font
      font-size: 4.8rem
      font-weight: 600
      line-height: 4.8rem
      text-align: center
      background: linear-gradient(100.16deg, #801FFF 2.7%, #A737EF 42.15%, #D856DB 91.66%)
      -webkit-background-clip: text
      -webkit-text-fill-color: transparent

    h3
      margin-top: 3.2rem
      color: #D3EAFD
      font-family: $default_font
      font-size: 1.6rem
      font-weight: 400
      line-height: 3rem
      text-align: center

    .hbspt-form
      form
        display: flex
        flex-direction: row
        align-items: flex-start
        align-content: flex-start
        justify-content: center
        width: 57.6rem
        margin: 3.2rem auto 0
        max-width: 100%

        +mediaMaxWidth(520.98px)
          flex-direction: column

        .hs_email
          width: 100%
          margin-right: 1.2rem

          +mediaMaxWidth(520.98px)
            margin-right: 0
            margin-bottom: 1.2rem

          input
            width: 100%
            height: 5.2rem
            border: .1rem solid #07275480
            background-color: $color_white
            border-radius: .8rem
            padding: 1.2rem 2.4rem

            +placeholder
              color: #07275480

          .hs-error-msgs
            list-style: none

            .hs-error-msg
              display: block
              margin: 1rem 0
              color: red

        .hs_submit
          +mediaMaxWidth(520.98px)
            width: 100%

          .actions
            position: relative
            cursor: pointer
            appearance: none
            width: 100%
            height: 5.2rem
            background-color: transparent
            border: .2rem solid #706D6A
            border-radius: .8rem
            outline: none
            padding: 1.6rem
            color: $color_white
            font-family: $default_font
            font-size: 1.6rem
            font-weight: 500
            line-height: 2rem
            text-shadow: .1rem 0 .3rem #00000040
            text-align: center

            +mediaMinWidth(521px)
              min-width: 16.5rem
              width: 16.5rem

            span
              position: relative
              z-index: 1

            input
              z-index: 1
              cursor: pointer
              position: absolute
              top: 0
              left: 0
              width: 100%
              height: 100%
              background-color: transparent
              border: none
              outline: none
              color: $color_white
              text-shadow: .1rem 0 .3rem #00000040

            &:before,
            &:after
              content: ''
              position: absolute
              top: 0
              left: 0
              width: 100%
              height: 100%
              border-radius: .6rem
              transition: 300ms all ease-out

            &:before
              background-image: linear-gradient(90deg, #FFDD2C 0%, #FF9314 100%)

            &:after
              opacity: 0
              visibility: hidden
              background-image: linear-gradient(90deg, #FF9314 0%, #FFDD2C 100%)

            &:hover
              &:after
                opacity: 1
                visibility: visible
        .hs_error_rollup
          display: none !important

      .submitted-message
        color: #D3EAFD
        font-family: $default_font
        font-size: 1.8rem
        font-weight: 400
        line-height: 2.2rem
        text-align: center

  &__nav
    padding: 4rem 1.4rem
    width: 100%
    max-width: 124rem
    color: $color_white

    +mediaMinWidth(768px)
      padding: 14.4rem 2.4rem 0

    &--columns
      display: flex
      justify-content: space-between

      +mediaMaxWidth(1024px)
        flex-wrap: wrap

      .footer-column
        width: max-content
        max-width: 100%
        padding: 1rem
        box-sizing: border-box

        +mediaMaxWidth(1180.98px)
          width: 100%

        +mediaMinWidth(768px)
          +mediaMaxWidth(1024px)
            flex-basis: 33.33%
            flex-grow: 1

        &__title
          cursor: pointer
          display: flex
          align-items: center
          align-content: center
          margin-bottom: 2.5rem
          color: $color_white
          font-family: $default_font
          font-size: 2.2rem
          font-weight: 600
          line-height: 1.6rem

          +mediaMinWidth(768px)
            cursor: initial

          &-social
            color: #DCFF4F

          svg
            margin: auto 0 auto auto
            transform: rotate(-90deg)
            transition: 300ms all ease-out

            +mediaMinWidth(768px)
              display: none

          &.visible
            svg
              transform: rotate(0deg)

        &__list
          display: none
          flex-direction: column
          margin-bottom: 2.5rem

          +mediaMinWidth(768px)
            display: flex !important

          a
            color: #C0C0C0
            font-family: $default_font
            font-size: 1.6rem
            font-weight: 400
            line-height: 2.4rem
            transition: 300ms all ease-out

            &:hover
              color: #DCFF4F

        &__social
          display: flex
          align-items: center
          align-content: center
          color: #DCFF4F

          a
            & + a
              margin-left: 1rem

          +mediaMaxWidth(767.98px)
            margin-bottom: 2.5rem

      .language
        margin-top: 4rem
        position: relative

        +mediaMaxWidth(767.98px)
          margin-bottom: 5rem

        .language-opener
          cursor: pointer
          background: transparent
          border: none
          outline: none
          padding: 1rem 0
          font-family: $default_font
          font-size: 1.6rem
          font-weight: 500
          line-height: 2.4rem
          color: $color_white
          transition: all 300ms ease-in-out

          +mediaMaxWidth(1030px)
            text-align: center

          svg
            position: relative
            top: .3rem

            path
              fill: $color_white
              transition: all 300ms ease-in-out

          &:hover
            color: #DCFF4F

            svg
              path
                fill: #DCFF4F

        .language-list
          position: absolute
          top: 32px
          left: 0
          right: 0
          display: none

          a
            cursor: pointer
            background: transparent
            border: none
            outline: none
            padding: 1rem 0
            font-family: $default_font
            font-size: 1.6rem
            font-weight: 500
            line-height: 2.4rem
            color: $color_white
            transition: all 300ms ease-in-out

            &:hover
              color: #DCFF4F

        &.visible
          .language-opener
            svg
              transform: rotate(180deg)

          .language-list
            display: block

  &__links
    //max-width: 119.2rem
    width: 100%
    margin: 0 auto
    display: flex
    justify-content: center
    align-items: center
    padding-bottom: 4rem

    +mediaMinWidth(768px)
      margin: 10rem auto 0

    &--list
      list-style-type: none
      display: flex
      margin: 0
      padding: 0

      +mediaMaxWidth(1030px)
        margin: 0 auto
        flex-direction: column

      li
        display: flex
        justify-content: center

        a
          cursor: pointer
          padding: 1rem
          font-family: $default_font
          font-size: 1.6rem
          font-weight: 500
          line-height: 2.4rem
          color: $color_white
          transition: all 300ms ease-in-out

          +mediaMaxWidth(1030px)
            text-align: center

          &:hover
            color: #DCFF4F
