/* Flex Grid Utilities */

/* Flex Container */
.flex-container {
    display: flex;
    flex-wrap: wrap;
    margin: -10px; /* Adjust as needed */
}

/* Flex Column Base */
.flex-col {
    box-sizing: border-box;
    padding: 10px; /* Adjust as needed */
}

/* Column Widths */
@for $i from 1 through 12 {
    .col-#{$i} {
        flex: 0 0 calc( #{$i} / 12 * 100% );
        max-width: calc( #{$i} / 12 * 100% );
    }
}



/* Responsive Breakpoints */
@media (max-width: 768px) {
    .flex-container {
        justify-content: center; /* Center items on smaller screens */
    }
    .flex-col {
        flex: 0 0 50%; /* Two columns on smaller screens */
        max-width: 50%;
    }
}

/* Additional Responsive Breakpoints */
@media (max-width: 480px) {
    .flex-col {
        flex: 0 0 100%; /* Full width on the smallest screens */
        max-width: 100%;
    }
}

/* Ensure child elements don't exceed container width */
.card {
    width: 100%; /* or max-width: 100%; */
    box-sizing: border-box;
}
