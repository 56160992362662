html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video
  margin: 0
  padding: 0
  border: 0
  font-size: 100%
  vertical-align: baseline
  outline: none

*
  box-sizing: border-box
  &:focus
    outline: none

  &:focus
    outline: none

html
  position: relative
  font-size: 62.5%

body, html
  padding: 0
  margin: 0
  width: 100%

body
  position: relative
  padding-right: 0 !important
  font-size: 1.6rem
  font-family: $default_font
  font-weight: 400

  &.no-scroll
    overflow: hidden

a
  text-decoration: none
  display: inline-block
  &:hover,
  &:focus,
  &:active
    outline: none
  &:hover
    text-decoration: none

  list-style-type: none

table
  border-collapse: collapse
table, th, td
  border: 1px solid $color_black
  padding: 15px
  text-align: left

button
  appearance: none
  border: 0

.none
  display: none

.cursor
  cursor: pointer

.clearfix
  &:before,
  &:after
    content: ""
    display: table

  &:after
    clear: both
