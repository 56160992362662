.cta
  font-size: 1em
  padding: 1em 2.5em
  line-height: 1em
  border-radius: 3.5em
  background-color: #3DB5E6
  border: 1px solid #3DB5E6
  display: inline-block
  text-align: center
  color: $color_white
  font-weight: 500
  text-transform: uppercase
  cursor: pointer

  &_outline
    font-size: 1em
    padding: 1em 2.5em
    line-height: 1em
    border-radius: 3.5em
    border: 1px solid #3DB5E6
    background-color: transparent
    display: inline-block
    text-align: center
    color: #3DB5E6
    font-weight: 600
    cursor: pointer

  &_white
    font-size: 1em
    padding: 1em 2.5em
    line-height: 1em
    border-radius: 3.5em
    background-color: $color_white
    border: 1px solid $color_white
    display: inline-block
    text-align: center
    color: #3DB5E6
    font-weight: 600
    cursor: pointer

.pastille
  color: $color_white
  background-color: #345F7C
  font-size: 12px
  font-weight: 600
  padding: 4px 8px
  border-radius: 20px

.underlined
  font-size: 1em
  line-height: 1.25em
  padding-bottom: .15em
  font-weight: 400
  color: inherit
  display: inline-block
  border-bottom: 1px solid

.cta_bg_blue
    padding: 1.7rem 2.4rem
    border-radius: 8px
    background-color: #072652
    width: fit-content
    color: #fff
    display: inline-block
    vertical-align: middle
    text-align: center
    cursor: pointer
    transition: all 300ms ease-out
    border: .2rem solid $color_black

    body.header-dark &
      border-color: #DCFF4F
      background-color: #DCFF4F
      color: $color_black

    &:hover
      background-color: #8A8585

      body.header-dark &
        border-color: $color_white
        background-color: #A1A1A1

    &:focus
      outline: .3rem solid #E09191
      outline-offset: 0

.cta_bg_white
    background-color: #fff
    width: fit-content
    color: #000
    padding: 14px 31px
    border-radius: 9px
    display: inline-block
    vertical-align: middle
    text-align: center
    border: 1px solid #000
    margin-right: 10px
    cursor: pointer
    transition: all 300ms ease-out

    &:hover
        background-color: #0a1e50
        color: #fff



.btn_border_multicolor
    background-color: $color_white
    width: fit-content
    color: #000
    padding: 14px 31px
    display: inline-block
    vertical-align: middle
    text-align: center
    border: 3px solid
    border-image-slice: 3
    border-image-source: linear-gradient(to right, #082856, #1D69A5, #2196F3, #DCFF4FCC)
    border-radius: 8px
    cursor: pointer
    transition: all 300ms ease-out

    &:hover
        background-color: $color_black
        color: #fff

.button-bg-white-border-black
    font-family: $default_font
    font-size: 1.6rem
    font-weight: 500
    line-height: 2rem
    color: $color_black
    background-color: $color_white
    border: 2px solid $color_black
    border-radius: 8px
    padding: 2.4rem
    cursor: pointer

    &:hover
        background-color: $color_black
        color: $color_white



.button-purple-color-white
    font-family: $default_font
    font-size: 1.6rem
    font-weight: 500
    line-height: 2rem
    color: $color_white
    background-color: #6161FD
    border: 2px solid #6161FD
    border-radius: 8px
    padding: 2.4rem
    cursor: pointer


