.no
  &-mg
    margin: 0
  &-mt
    margin-top: 0
  &-mb
    margin-bottom: 0
  &-ml
    margin-left: 0
  &-md
    margin-right: 0


.ta
  &c
    text-align: center
  &l
    text-align: left
  &r
    text-align: right
  &j
    text-align: justify

.p
  &-8
    padding: 8px
  &-16
    padding: 16px
  &-24
    padding: 24px
  &-32
    padding: 32px

  &l
    &-8
      padding-left: 8px
    &-16
      padding-left: 16px
    &-24
      padding-left: 24px
    &-32
      padding-left: 32px

  &r
    &-8
      padding-right: 8px
    &-16
      padding-right: 16px
    &-24
      padding-right: 24px
    &-32
      padding-right: 32px

  &t
    &-8
      padding-top: 8px
    &-16
      padding-top: 16px
    &-24
      padding-top: 24px
    &-32
      padding-top: 32px

  &b
    &-8
      padding-bottom: 8px
    &-16
      padding-bottom: 16px
    &-24
      padding-bottom: 24px
    &-32
      padding-bottom: 32px
.m
  &-8
    margin: 8px
  &-16
    margin: 16px
  &-24
    margin: 24px
  &-32
    margin: 32px

  &l
    &-8
      margin-left: 8px
    &-16
      margin-left: 16px
    &-24
      margin-left: 24px
    &-32
      margin-left: 32px

  &r
    &-8
      margin-right: 8px
    &-16
      margin-right: 16px
    &-24
      margin-right: 24px
    &-32
      margin-right: 32px

  &t
    &-8
      margin-top: 8px
    &-16
      margin-top: 16px
    &-24
      margin-top: 24px
    &-32
      margin-top: 32px

  &b
    &-8
      margin-bottom: 8px
    &-16
      margin-bottom: 16px
    &-24
      margin-bottom: 24px
    &-32
      margin-bottom: 32px