@import "resources/sass/helpers/normalize"
@import "../base"
@import "resources/sass/components/button"

// default
.page
  &-banner
    width: 100%
    position: relative

    &--image
      height: 401px
      width: 100%
      object-fit: cover
      position: relative
      z-index: 8

      +mediaMaxWidth(550px)
        height: 250px

    &--title
      max-width: 1192px
      box-sizing: content-box
      left: 50%
      transform: translateX(-50%)
      bottom: 0
      padding: 40px 20px
      font-size: 60px
      line-height: 1.05em
      font-weight: 500
      color: $color_white
      position: absolute
      z-index: 88
      width: calc(100% - 40px)

      +mediaMaxWidth(1024px)
        font-size: 40px

      +mediaMaxWidth(550px)
        font-size: 32px
        text-align: center

  &-header
    padding: 50px 20px 0
    box-sizing: content-box
    max-width: 1192px
    margin: auto

  &-title
    font-size: 60px
    line-height: 1.15em
    color: $color_black
    font-weight: 500
    padding-bottom: 40px
    margin: auto

    +mediaMaxWidth(1024px)
      font-size: 40px

    +mediaMaxWidth(550px)
      font-size: 32px
      text-align: center

  &-subtitle
    font-size: 32px
    line-height: 1.15em
    color: $color_black
    font-weight: 600
    padding-bottom: 40px

    +mediaMaxWidth(1024px)
      font-size: 25px

    +mediaMaxWidth(550px)
      font-size: 20px
      text-align: center

  &-container
    padding: 0 20px
    box-sizing: content-box
    max-width: 1192px
    margin: auto

.contacts
  padding: 0 20px 80px
  box-sizing: content-box
  max-width: 1192px
  margin: auto

  &-container
    max-width: 1150px
    margin: auto

.section-content
  padding: 6.2rem 0 0
  margin-bottom: 6rem
  background: linear-gradient(180deg, #EEEEEE 2%, #FFFFFF 20.13%)

  &__container
    background: linear-gradient(180deg, #F6F6F6 0%, #909090 100%)
    border-radius: 2.4rem
    padding: .2rem
    overflow: hidden

    &--inner
      z-index: 1
      position: relative
      width: 100%
      height: auto
      background-color: $color_white
      border-radius: 2.2rem
      padding: 5.4rem
      overflow: hidden

    .hero
      &__container
        width: 100%
        margin: 0 auto
        text-align: center

        +displayFlex(column, flex-start, center)

        &--categories-date
          display: flex
          align-items: center
          align-content: center
          margin-bottom: 4.8rem

        &--categories,
        &--date
          display: flex
          align-items: center
          align-content: center
          color: #2196F3
          font-family: $default_font
          font-size: 1.8rem
          font-weight: 500
          line-height: 2.4rem
          letter-spacing: 0.01em
          text-transform: uppercase

        &--categories
          & + .hero__container--date
            &:before
              content: '•'
              display: flex
              margin: 0 .4rem

        &--abovetitle
          font-family: $default_font
          font-size: 1.8rem
          font-weight: 600
          line-height: 2.4rem
          letter-spacing: 0.01em
          color: #00579E
          text-transform: uppercase

        &--title
          color: #003968
          margin-top: 1.6rem
          font-family: $default_font
          font-size: 4.8rem
          font-weight: 600
          line-height: 5.2rem

        &--subtitle
          margin-top: 2.4rem
          font-family: $default_font
          font-size: 2.2rem
          font-weight: 500
          line-height: 3.2rem
          color: #5F5F5FCC

  .content
    &__container
      &--banner
        margin-top: 4.8rem

        img
          width: 100%
          height: auto
          filter: drop-shadow(0 .4rem .8rem rgba(0, 0, 0, 0.12))
          border-radius: 2.4rem
          overflow: hidden

      .entry-content
        margin-top: 4.8rem
        color: $color_black
        font-family: $default_font
        font-size: 2.2rem
        font-weight: 400
        line-height: 3.6rem

        p
          color: $color_black
          font-family: $default_font
          font-size: 2.2rem
          font-weight: 400
          line-height: 3.6rem

          & + p
            margin-top: 2.4rem

        strong,
        b
          color: $color_black
          font-weight: 600

        a
          color: $color_black
          transition: 350ms all ease-in-out
          text-decoration: underline
          text-underline-offset: .2rem
          text-decoration-thickness: .15rem

        img
          margin: auto
          max-width: 100% !important
          height: auto !important

          +mediaMaxWidth(767.98px)
            margin: 1rem 0 !important
            width: 100% !important

            & + img
              margin-top: 0 !important

        ul
          margin: 0
          padding-left: 2.8rem

          li
            list-style: disc

        iframe
          max-width: 100%

.container-error-page
  text-align: center
  padding: 8rem 1.6rem

  h1
    text-align: center
    font-size: 7rem
    color: #07234b
