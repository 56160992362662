@font-face
    font-family: 'Poppins'
    src: url('/webfonts/poppins/Poppins-Bold.woff2') format('woff2')
    font-weight: bold
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Poppins'
    src: url('/webfonts/poppins/Poppins-BoldItalic.woff2') format('woff2')
    font-weight: bold
    font-style: italic
    font-display: swap


@font-face
    font-family: 'Poppins'
    src: url('/webfonts/poppins/Poppins-Light.woff2') format('woff2')
    font-weight: 300
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Poppins'
    src: url('/webfonts/poppins/Poppins-ExtraBold.woff2') format('woff2')
    font-weight: bold
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Poppins'
    src: url('/webfonts/poppins/Poppins-ExtraLightItalic.woff2') format('woff2')
    font-weight: 200
    font-style: italic
    font-display: swap

@font-face
    font-family: 'Poppins'
    src: url('/webfonts/poppins/Poppins-Italic.woff2') format('woff2')
    font-weight: normal
    font-style: italic
    font-display: swap

@font-face
    font-family: 'Poppins'
    src: url('/webfonts/poppins/Poppins-ExtraBoldItalic.woff2') format('woff2')
    font-weight: bold
    font-style: italic
    font-display: swap

@font-face
    font-family: 'Poppins'
    src: url('/webfonts/poppins/Poppins-ExtraLight.woff2') format('woff2')
    font-weight: 200
    font-style: normal
    font-display: swap


@font-face
    font-family: 'Poppins'
    src: url('/webfonts/poppins/Poppins-Thin.woff2') format('woff2')
    font-weight: 100
    font-style: normal
    font-display: swap


@font-face
    font-family: 'Poppins'
    src: url('/webfonts/poppins/Poppins-SemiBoldItalic.woff2') format('woff2')
    font-weight: 600
    font-style: italic
    font-display: swap


@font-face
    font-family: 'Poppins'
    src: url('/webfonts/poppins/Poppins-MediumItalic.woff2') format('woff2')
    font-weight: 500
    font-style: italic
    font-display: swap

@font-face
    font-family: 'Poppins'
    src: url('/webfonts/poppins/Poppins-Medium.woff2') format('woff2')
    font-weight: 500
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Poppins'
    src: url('/webfonts/poppins/Poppins-SemiBold.woff2') format('woff2')
    font-weight: 600
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Poppins'
    src: url('/webfonts/poppins/Poppins-Regular.woff2') format('woff2')
    font-weight: normal
    font-style: normal
    font-display: swap


@font-face
    font-family: 'Poppins'
    src: url('/webfonts/poppins/Poppins-ThinItalic.woff2') format('woff2')
    font-weight: 100
    font-style: italic
    font-display: swap

@font-face
    font-family: 'Poppins'
    src: url('/webfonts/poppins/Poppins-LightItalic.woff2') format('woff2')
    font-weight: 300
    font-style: italic
    font-display: swap


@font-face
    font-family: 'Poppins'
    src: url('/webfonts/poppins/Poppins-Black.woff2') format('woff2')
    font-weight: 900
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Poppins'
    src: url('/webfonts/poppins/Poppins-BlackItalic.woff2') format('woff2')
    font-weight: 900
    font-style: italic
    font-display: swap

