@import "resources/sass/helpers/font-face"
@import "resources/sass/components/cookies"
@import "resources/sass/components/cart"
@import "resources/sass/layout/grid"
@import "resources/sass/layout/grid_flex"
@import "resources/sass/helpers/helpers"
@import "resources/sass/helpers/mixins"
@import "resources/sass/helpers/variables"
@import "resources/sass/helpers/reset"
@import "resources/sass/layout/header"
@import "resources/sass/layout/footer"

.theme-main
    width: 100%
    margin: auto
    word-break: break-word
    word-wrap: break-word

    &__container
        width: 100%
        margin: 0 auto

        .max-width-theme
            max-width: 122.4rem
            padding: 0 1.6rem
            width: 100%
            margin: 0 auto

        &--title
            margin: 30px auto 0

        &--section
            width: 100%
            margin: 0 auto

            &.pb-80
                padding-bottom: 80px

        #app
            width: 100%

            +mediaMaxWidth(430px)
                padding: 0 20px

            .theme-main__container--section
                width: 100%
                margin: 0 auto

                &.pb-80
                    padding-bottom: 80px

                .max-width-theme
                    max-width: 1192px
                    width: 100%
                    margin: 0 auto



h1
    margin: 0 auto


.only-desktop
  +mediaMaxWidth(991.98px)
    display: none !important
.only-mobile
  +mediaMinWidth(992px)
    display: none !important
