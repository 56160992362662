@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    display: block;
    clear: both;
    height: 1px;
    margin-top: -1px;
    visibility: hidden;
  }
  & {
    *zoom: 1;
  }
}

@mixin mediaMaxWidth($w) {
  @media (max-width: $w) {
    @content;
  }
}

@mixin mediaMinWidth($w) {
  @media (min-width: $w) {
    @content;
  }
}

@mixin padding-bottom($padding) {
  height: 0;
  overflow: hidden;
  padding-bottom: $padding;
  position: relative;
}

@mixin img-cover() {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit:cover';
}

@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
}

@mixin placeholder() {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin flexbox($justify,$align,$wrap,$direction) {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-wrap: $wrap;
  flex-direction: $direction;
}

@mixin absCenter() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin borderCornerRadius($left-bottom, $left-top, $right-bottom, $right-top) {
  border-bottom-left-radius: $left-bottom;
  border-top-left-radius: $left-top;
  border-bottom-right-radius: $right-bottom;
  border-top-right-radius: $right-top;
}

@mixin grid() {
  &--full {
    width: 100%
  }

  &--1-2 {
    width: calc((100% - #{$gutter-horizontal}) / 2);
  }

  &--1-3 {
    width: calc((100% - 2 * #{$gutter-horizontal}) / 3);
  }

  &--2-3 {
    width: calc(2 * (100% / 3) - #{$gutter-horizontal});
  }

  &--1-4 {
    width: calc((100% - 3 * #{$gutter-horizontal}) / 4);
  }

  &--2-4 {
    width: calc(2 * (100% / 4) - #{$gutter-horizontal});
  }

  &--3-4 {
    width: calc(3 * (100% / 4) - #{$gutter-horizontal});
  }

  &--1-5 {
    width: calc(100% / 5);
  }

  &--2-5 {
    width: calc(2 * (100% / 5) - #{$gutter-horizontal});
  }

  &--3-5 {
    width: calc(3 * (100% / 5) - #{$gutter-horizontal});
  }

  &--4-5 {
    width: calc(4 * (100% / 5) - #{$gutter-horizontal});
  }
}

@mixin screenSize($breakpoint) {

  @if $breakpoint == small {
    @media only screen and (max-width: 768px) {
      @content
    }
  }
  @if $breakpoint == medium {
    @media only screen and (min-width: 768px) {
      @content
    }
  }
  @if $breakpoint == large {
    @media only screen and (min-width: 992px) {
      @content
    }
  }
  @if $breakpoint == xlarge {
    @media only screen and (min-width: 1300px) {
      @content
    }
  }
  @if $breakpoint == xxlarge {
    @media only screen and (min-width: 1500px) {
      @content
    }
  }

}

/***** end loops ****/
@-webkit-keyframes hideAndShow {
  from {
    opacity: 0;
  }
}

@keyframes hideAndShow {
  from {
    opacity: 0;
  }
}

@mixin displayFlex($flexDirection, $justifyContent, $alignItems) {
  display: flex;
  flex-direction: $flexDirection;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin displayGrid($gridTemplateColumns, $columnGap, $rowGap: initial) {
  display: grid;
  grid-template-columns: $gridTemplateColumns;
  column-gap: $columnGap;
  row-gap: $rowGap;
}

//@function toRem($value) {
//  $remValue: ($value / 16) + rem;
//  @return $remValue;
//}

$browser-context: 16;
//
//@function toEm($pixels, $context: $browser-context) {
//  @return #{$pixels/$context}em;
//}


@function setBackgroundColor() {
  @return $color;
}


$lightHeaderBgMode: #FFF;
$lightHeaderTextMode: #000;

$darkHeaderBgMode: #000;
$darkHeaderTextMode: #FFF;

@mixin setHeaderStyle($light-theme: true) {
  @if $light-theme {
    background-color: $lightHeaderBgMode;
    color: $lightHeaderTextMode;
  } @else {
    background-color: $darkHeaderBgMode;
    color: $darkHeaderTextMode;

    svg {

    }

  }
}
