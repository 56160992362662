/* ====================
	* GRID *
==================== */
.grid {
  display: grid;
  gap: 28px;
}


.col-1 { grid-template-columns: repeat(1, 1fr); }
.col-2 { grid-template-columns: repeat(2, 1fr); }
.col-3 { grid-template-columns: repeat(3, 1fr); }
.col-4 { grid-template-columns: repeat(4, 1fr); }
.col-5 { grid-template-columns: repeat(5, 1fr); }
.col-6 { grid-template-columns: repeat(6, 1fr); }
.col-7 { grid-template-columns: repeat(7, 1fr); }
.col-8 { grid-template-columns: repeat(8, 1fr); }
.col-9 { grid-template-columns: repeat(9, 1fr); }
.col-10 { grid-template-columns: repeat(10, 1fr); }
.col-11 { grid-template-columns: repeat(11, 1fr); }
.col-12 { grid-template-columns: repeat(12, 1fr); }

.span-1 { grid-column: span 1; }
.span-2 { grid-column: span 2; }
.span-3 { grid-column: span 3; }
.span-4 { grid-column: span 4; }
.span-5 { grid-column: span 5; }
.span-6 { grid-column: span 6; }
.span-7 { grid-column: span 7; }
.span-8 { grid-column: span 8; }
.span-9 { grid-column: span 9; }
.span-10 { grid-column: span 10; }
.span-11 { grid-column: span 11; }
.span-12 { grid-column: span 12; }

@media screen and (max-width: 1200px) {
  .lg-col-1 { grid-template-columns: repeat(1, 1fr); }
  .lg-col-2 { grid-template-columns: repeat(2, 1fr); }
  .lg-col-3 { grid-template-columns: repeat(3, 1fr); }
  .lg-col-4 { grid-template-columns: repeat(4, 1fr); }
  .lg-col-5 { grid-template-columns: repeat(5, 1fr); }
  .lg-col-6 { grid-template-columns: repeat(6, 1fr); }
  .lg-col-7 { grid-template-columns: repeat(7, 1fr); }
  .lg-col-8 { grid-template-columns: repeat(8, 1fr); }
  .lg-col-9 { grid-template-columns: repeat(9, 1fr); }
  .lg-col-10 { grid-template-columns: repeat(10, 1fr); }
  .lg-col-11 { grid-template-columns: repeat(11, 1fr); }
  .lg-col-12 { grid-template-columns: repeat(12, 1fr); }

  .lg-span-1 { grid-column: span 1; }
  .lg-span-2 { grid-column: span 2; }
  .lg-span-3 { grid-column: span 3; }
  .lg-span-4 { grid-column: span 4; }
  .lg-span-5 { grid-column: span 5; }
  .lg-span-6 { grid-column: span 6; }
  .lg-span-7 { grid-column: span 7; }
  .lg-span-8 { grid-column: span 8; }
  .lg-span-9 { grid-column: span 9; }
  .lg-span-10 { grid-column: span 10; }
  .lg-span-11 { grid-column: span 11; }
  .lg-span-12 { grid-column: span 12; }

}

@media screen and (max-width: 1024px) {
  .md-col-1 { grid-template-columns: repeat(1, 1fr); }
  .md-col-2 { grid-template-columns: repeat(2, 1fr); }
  .md-col-3 { grid-template-columns: repeat(3, 1fr); }
  .md-col-4 { grid-template-columns: repeat(4, 1fr); }
  .md-col-5 { grid-template-columns: repeat(5, 1fr); }
  .md-col-6 { grid-template-columns: repeat(6, 1fr); }
  .md-col-7 { grid-template-columns: repeat(7, 1fr); }
  .md-col-8 { grid-template-columns: repeat(8, 1fr); }
  .md-col-9 { grid-template-columns: repeat(9, 1fr); }
  .md-col-10 { grid-template-columns: repeat(10, 1fr); }
  .md-col-11 { grid-template-columns: repeat(11, 1fr); }
  .md-col-12 { grid-template-columns: repeat(12, 1fr); }

  .md-span-1 { grid-column: span 1; }
  .md-span-2 { grid-column: span 2; }
  .md-span-3 { grid-column: span 3; }
  .md-span-4 { grid-column: span 4; }
  .md-span-5 { grid-column: span 5; }
  .md-span-6 { grid-column: span 6; }
  .md-span-7 { grid-column: span 7; }
  .md-span-8 { grid-column: span 8; }
  .md-span-9 { grid-column: span 9; }
  .md-span-10 { grid-column: span 10; }
  .md-span-11 { grid-column: span 11; }
  .md-span-12 { grid-column: span 12; }
}


@media screen and (max-width: 680px) {
  .sm-col-1 { grid-template-columns: repeat(1, 1fr); }
  .sm-col-2 { grid-template-columns: repeat(2, 1fr); }
  .sm-col-3 { grid-template-columns: repeat(3, 1fr); }
  .sm-col-4 { grid-template-columns: repeat(4, 1fr); }
  .sm-col-5 { grid-template-columns: repeat(5, 1fr); }
  .sm-col-6 { grid-template-columns: repeat(6, 1fr); }
  .sm-col-7 { grid-template-columns: repeat(7, 1fr); }
  .sm-col-8 { grid-template-columns: repeat(8, 1fr); }
  .sm-col-9 { grid-template-columns: repeat(9, 1fr); }
  .sm-col-10 { grid-template-columns: repeat(10, 1fr); }
  .sm-col-11 { grid-template-columns: repeat(11, 1fr); }
  .sm-col-12 { grid-template-columns: repeat(12, 1fr); }

  .sm-span-1 { grid-column: span 1; }
  .sm-span-2 { grid-column: span 2; }
  .sm-span-3 { grid-column: span 3; }
  .sm-span-4 { grid-column: span 4; }
  .sm-span-5 { grid-column: span 5; }
  .sm-span-6 { grid-column: span 6; }
  .sm-span-7 { grid-column: span 7; }
  .sm-span-8 { grid-column: span 8; }
  .sm-span-9 { grid-column: span 9; }
  .sm-span-10 { grid-column: span 10; }
  .sm-span-11 { grid-column: span 11; }
  .sm-span-12 { grid-column: span 12; }
}
