/** COLOR **/
$color_white: #FFF
$color_black: #000
$color_blue: #001E50
$color_grey: #C0C0C0
$color_yellow: #DCFF4F

/** FONT **/

$default_font: 'Poppins', sans-serif

@keyframes rotateGradient
  0%
    border-image-source: linear-gradient(97.07deg, #082856 49.28%, #1D69A5 65.99%, #2196F3 85.39%, rgba(220, 255, 79, 0.8) 101.02%)
  25%
    border-image-source: linear-gradient(93.12deg, #082856 48.8%, #1D69A5 65%, #2196F3 83.8%, rgba(220, 255, 79, 0.8) 98.95%)
  50%
    border-image-source: linear-gradient(304.15deg, #082856 48.34%, #1D69A5 59.64%, #2196F3 72.77%, rgba(220, 255, 79, 0.8) 83.34%)
  75%
    border-image-source: linear-gradient(200.12deg, #082856 55.18%, #1D69A5 65.63%, #2196F3 77.77%, rgba(220, 255, 79, 0.8) 87.54%)
  100%
    border-image-source: linear-gradient(129.31deg, #082856 52.03%, #1D69A5 59.5%, #2196F3 68.18%, rgba(220, 255, 79, 0.8) 75.17%)
